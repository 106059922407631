import Logo from "@/assets/images/logo4.png";
import Logo2 from "@/assets/images/logo5.png";
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: "L OFFICE",
      logo: Logo,
      logo2: Logo2
    };
  }
};
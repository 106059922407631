import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.showModel,
      "custom-class": "dialog"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showModel = $event;
      }
    }
  }, [_c("div", {
    staticClass: "title",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      "icon-class": "warning"
    }
  }), _vm._v("\n    关注公众号后可接收小程序的消息提醒\n  ")], 1), _vm._v(" "), _c("div", [_c("div", {
    staticClass: "tabs"
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tabs-item",
      class: {
        "active-item": _vm.tabIndex === index
      },
      on: {
        click: function click($event) {
          return _vm.changeTab(index);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "tip"
  }, [_vm._v("请用微信扫描图中二维码")]), _vm._v(" "), _vm.tabIndex == 1 ? _c("div", {
    staticClass: "codecontent"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/images/gz_code.jpg")
    }
  }), _vm._v(" "), _c("div", [_vm._v("L OFFICE陆家嘴办公")])]) : _vm.tabIndex == 0 ? _c("div", {
    staticClass: "codecontent"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/images/mini_code.jpg")
    }
  }), _vm._v(" "), _c("div", [_vm._v("L OFFICE")])]) : _vm._e()]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
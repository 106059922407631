import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.match";
import "/root/.jenkins/workspace/47.102.216.211-loffice-frontend-dev/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/.jenkins/workspace/47.102.216.211-loffice-frontend-dev/node_modules/core-js/modules/es6.promise.js";
import "/root/.jenkins/workspace/47.102.216.211-loffice-frontend-dev/node_modules/core-js/modules/es6.object.assign.js";
import "/root/.jenkins/workspace/47.102.216.211-loffice-frontend-dev/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from "vue";
import Cookies from "js-cookie";
import "normalize.css/normalize.css";
import "@/styles/vab.scss";
import Element from "element-ui";
//
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";

// 数据字典
import dict from "./components/Dict";

// 权限指令
import checkPer from "@/utils/permission";
import permission from "./components/Permission";
import "./assets/styles/element-variables.scss";
// global css
import "./assets/styles/index.scss";

// 代码高亮
import VueHighlightJS from "vue-highlightjs";
import "highlight.js/styles/atom-one-dark.css";
import { numberToCurrencyNo } from "@/utils/numberToCurrency";
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./assets/icons"; // icon
import "./router/index"; // permission control
import "echarts-gl";
import lodash from "lodash";

// 时间格式插件
import moment from "moment";
Vue.prototype.$moment = moment;
moment.locale("zh-cn");

// 配置全局过滤器，实现数字千分位格式
Vue.filter("numberToCurrency", numberToCurrencyNo);
Vue.prototype.$lodash = lodash;
Vue.use(checkPer);
Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(dict);
Vue.use(Element, {
  size: Cookies.get("size") || "small" // set element-ui default size
});

Vue.config.productionTip = false;
new Vue({
  el: "#app",
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
router.onError(function (error) {
  var pattern = /Loading chunk (\d)+ failed/g;
  var isChunkLoadFailed = error.message.match(pattern);
  var targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
import "core-js/modules/es6.regexp.to-string";
var CryptoJS = require('crypto-js');
var key = '8eyih5KqPdC28ZQb';
var keyUtf8 = CryptoJS.enc.Utf8.parse(key);
var ivUtf8 = CryptoJS.enc.Utf8.parse(key);

/**
 * AES 加密函数
 * @param {string|number} message - 需要加密的内容
 * @returns {string} - 加密后的字符串
 */
function encrypt(message) {
  if (message === undefined || message === null) {
    throw new Error('Invalid message to encrypt');
  }
  var dataStr = message.toString();
  var encrypted = CryptoJS.AES.encrypt(dataStr, keyUtf8, {
    iv: ivUtf8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

/**
 * AES 解密函数
 * @param {string} data - 需要解密的内容
 * @returns {string} - 解密后的字符串
 */
function decryptAES(data) {
  if (!data) {
    return data;
  }
  try {
    // 尝试解密
    var decrypted = CryptoJS.AES.decrypt(data, keyUtf8, {
      iv: ivUtf8,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    // 将解密后的数据转换为 UTF-8 字符串
    var decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);

    // 如果转换后的字符串为空，说明解密失败
    if (!decryptedStr) {
      console.error('Failed to decrypt data or invalid key/iv');
      return data; // 返回原始数据以防止错误
    }

    var text = decodeURIComponent(decryptedStr);
    return text;
  } catch (error) {
    // 捕获解密中的错误
    console.error('Error during AES decryption:', error);
    return data; // 返回原始数据以防止程序崩溃
  }
}

export { encrypt, decryptAES };
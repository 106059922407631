var decryptWhiteList = {
  'auth/idLogin': ['mobile', 'username', 'realName', 'phone', 'password'],
  'auth/info': ['mobile', 'username', 'realName', 'password'],
  'auth/login': ['mobile', 'username', 'realName', 'phone', 'password'],
  'auth/phoneLogin': ['mobile', 'userName', 'realName', 'phone', 'password'],
  'auth/wxCode': ['mobile', 'userName', 'realName', 'phone', 'password'],
  'api/account': ['realName', 'mobile', 'email', 'username', 'phone'],
  'api/account/getManger': ['realName', 'workRealName', 'email', 'phone'],
  'api/account/getOvertimeManger': ['realName', 'workRealName', 'email', 'phone'],
  'api/account/getOvertimeWorker': ['realName', 'workRealName', 'email', 'phone'],
  'api/account/getRepairWorker': ['realName', 'workRealName', 'email', 'phone'],
  'api/account/get-user-by-groupId': ['realName', 'userName', 'email', 'mobile'],
  'api/account/queryUserByProjectId': ['realName', 'email', 'phone', 'mobile', 'createBy', 'updateBy'],
  'api/account/query-user-group': ['createBy', 'updateBy'],
  'api/account/queryUserGroupByIdentity': ['createBy', 'updateBy'],
  'api/activity/get-business-by': ['phone'],
  'api/advise/get_advise_info': ['createBy'],
  'api/advise/list': ['name'],
  'api/bill/getCreateBy': ['createBy', 'recipient', 'payeeAccount', 'payeeBank', 'payer', 'createBy', 'carNo'],
  'api/bill/list': ['createBy', 'recipientStr', 'updateBy'],
  'api/bill/wxList': ['createBy', 'carNo', 'recipientStr'],
  'api/billItem/list': ['payer', 'createBy', 'carNo'],
  'api/employees': ['name', 'userName', 'mobile', 'email', 'sysUserGroupList.name'],
  'api/employees/get_company': ['adminPerson', 'userName', 'email', 'phone', 'email', 'createBy', 'updateBy'],
  'api/employees/get_employee_info': ['name', 'userName', 'email', 'mobile'],
  'api/employees/getAdminByLesseeId': ['name', 'userName', 'email', 'mobile'],
  'api/employees/getByLesseeId': ['name', 'userName', 'email', 'mobile'],
  'api/employees/import': ['name', 'userName', 'email', 'mobile'],
  'api/go-out/list': ['applyName', 'applyPhone', 'createBy', 'auditName', 'updateBy', 'mobile', 'verifyName'],
  'api/home/get-bill-histogram': ['createBy', 'carNo'],
  'api/home/get-repair-histogram': ['empName', 'empPhone', 'repairWorkerName'],
  'api/home/getVisitInvitation': ['empName', 'empPhone', 'visitName', 'mobile', 'createBy', 'certificateNo'],
  'api/lessee/getByProjectId': ['adminPerson', 'phone'],
  'api/lessee/info': ['adminPerson', 'userName', 'email', 'phone'],
  'api/lessee/list': ['realName', 'workRealName', 'email', 'phone', 'createBy'],
  'api/lessee/visit': ['adminPerson', 'email', 'phone', 'userName', 'name', 'mobile'],
  'api/lift/list': ['createBy', 'auditName', 'updateBy', 'verifyName'],
  'api/material/list': ['createBy', 'updateBy'],
  'api/msg/list': ['createBy'],
  'api/overtime/list': ['auditName', 'updateBy', 'flowName', 'createBy'],
  'api/parkApply': ['carNo', 'applyName', 'applyPhone', 'createBy'],
  'api/project/getProjectInfo': ['phone', 'createBy', 'updateBy'],
  'api/project/info': ['name', 'phone', 'openBank', 'bankAccount', 'payee', 'updateBy', 'createBy', 'emailAccount', 'emailPassword', 'emailFrom', 'emailPort'],
  'api/project/list': ['phone', 'createBy', 'updateBy'],
  'api/projectOffices/queryList': ['userName', 'createBy'],
  'api/projectSpaceUnit/list': ['createBy'],
  'api/repair/list': ['empName', 'empPhone', 'repairWorkerName'],
  'api/repair/log': ['flowName', 'createBy'],
  'api/scanLog/list': ['scanName', 'scanPhone', 'createBy', 'updateBy'],
  'api/statements/getExportWorkerStatistics': ['real_name'],
  'api/statements/getWorkerStatistics': ['real_name'],
  'api/sysNews/info': ['createBy'],
  'api/sysNews/list': ['createBy'],
  'api/verifyRecord/queryByBusinessId': ['createBy', 'auditName', 'updateBy', 'verifyName'],
  'api/visit/detailById': ['name', 'phone', 'cardCode'],
  'api/visit/getIsAutoAudit': ['createBy', 'updateBy'],
  'api/visit/import': ['empName', 'empPhone', 'mobile', 'visitName', 'certificateNo'],
  'api/visit/list': ['empName', 'empPhone', 'visitName', 'mobile', 'createBy', 'certificateNo'],
  'api/visit/queryByServiceId': ['certificateNo', 'mobile'],
  'api/visit/reachReport': ['empName', 'empPhone', 'visitName', 'mobile', 'certificateNo'],
  'api/visit/regReport': ['empName', 'empPhone', 'visitName', 'mobile', 'certificateNo', 'createBy'],
  'api/visitBlack': ['name', 'phone', 'cardCode', 'createBy', 'updateBy'],
  'api/visitBlack/list': ['name', 'phone', 'cardCode', 'createBy', 'updateBy'],
  '/app/advise/detail': ['name', 'createBy'],
  '/app/advise/getAllStaffId': ['name', 'createBy'],
  '/app/advise/list': ['name', 'createBy'],
  '/app/advise/myPage': ['name', 'createBy'],
  '/app/bill/detail': ['createBy', 'carNo'],
  '/app/bill/list': ['createBy', 'carNo'],
  '/app/employee/getBuildInfo': ['createBy'],
  '/app/employee/get-employee-list': ['userName', 'name', 'mobile'],
  '/app/employee/getScanDetail': ['userName', 'name', 'mobile'],
  '/app/employee/info': ['name', 'mobile'],
  '/app/employee/invitation-employee': ['name', 'mobile'],
  '/app/employee/queryEmpByCode': ['userName', 'name', 'email', 'mobile', 'createBy', 'updateBy'],
  '/app/employee/queryUserGroupByIdentity': ['createBy', 'updateBy'],
  '/app/employee/query-user-info-bycode': ['mobile', 'userName', 'realName'],
  '/app/employee/save-employee': ['name', 'mobile'],
  '/app/employee/switch-account-list': ['mobile', 'userName', 'realName'],
  '/app/employee/update-employee': ['name', 'mobile'],
  '/app/go-out-apply/go-out-apply-info': ['createBy', 'auditName', 'verifyName', 'applyName', 'applyPhone'],
  '/app/go-out-apply/go-out-apply-list': ['createBy', 'auditName', 'verifyName', 'applyName', 'applyPhone'],
  '/app/group-visit/anonymous/getById': ['visitName', 'mobile', 'createBy'],
  '/app/group-visit/getInvitationDetailByName': ['visitName', 'mobile'],
  '/app/group-visit/get-invitation-info': ['createBy', 'empName', 'empPhone', 'certificateNo'],
  '/app/group-visit/get-invitation-list': ['visitName', 'visitPhone', 'createBy'],
  '/app/group-visit/get-wx-info': ['phoneNumber', 'purePhoneNumber'],
  '/app/group-visit/visit/detail': ['certificateNo', 'visitName', 'mobile', 'createBy'],
  '/app/group-visit/visit/list': ['certificateNo', 'visitName', 'mobile', 'createBy'],
  '/app/home/get-user-info': ['name', 'mobile'],
  '/app/lift-apply/get-lift-apply-info': ['createBy', 'auditName', 'verifyName'],
  '/app/lift-apply/get-lift-apply-list': ['createBy', 'auditName', 'verifyName'],
  '/app/msgSend/listSerivce': ['createBy', 'userName', 'userPhone'],
  '/app/msgSend/queryServicCount': ['createBy', 'userName', 'userPhone'],
  '/app/overtime/getManger': ['realName', 'workRealName', 'email', 'phone', 'userName'],
  '/app/overtime/get-overtime-info': ['createBy', 'auditName', 'createPhone', 'managerName', 'managerPhone', 'workerName', 'workerPhone'],
  '/app/overtime/get-overtime-list': ['createBy', 'auditName', 'createPhone', 'managerName', 'managerPhone', 'workerName', 'workerPhone'],
  '/app/overtime/getWorker': ['realName', 'workRealName', 'email', 'phone', 'userName'],
  '/app/parkApply/auditList': ['carNo', 'applyName', 'applyPhone', 'createBy'],
  '/app/parkApply/detail': ['carNo', 'applyName', 'applyPhone', 'createBy'],
  '/app/parkApply/employeePage': ['carNo', 'applyName', 'applyPhone', 'createBy'],
  '/app/parkApply/payFeesList': ['carNo', 'applyName', 'applyPhone', 'createBy'],
  '/app/project/get-project-business': ['phone'],
  '/app/property/applyDetail': ['verifyName', 'createBy'],
  '/app/property/applyList': ['verifyName', 'createBy'],
  '/app/property/messageList': ['createBy', 'userName', 'userPhone'],
  '/app/property/parkApplyList': ['applyName', 'applyPhone', 'carNo', 'createBy'],
  '/app/repair/assignList': ['empName', 'empPhone', 'repairWorkerName'],
  '/app/repair/detail': ['flowName', 'createBy', 'updateBy', 'empName', 'empPhone', 'managerName', 'managerPhone', 'repairWorkerName', 'repairWorkerPhone'],
  '/app/repair/getManger': ['realName', 'workRealName', 'userName', 'email', 'phone'],
  '/app/repair/getRepairWorker': ['realName', 'workRealName', 'userName', 'email', 'phone'],
  '/app/repair/list': ['empName', 'empPhone', 'repairWorkerName'],
  '/app/repair/myList': ['empName', 'empPhone', 'repairWorkerName'],
  '/app/repair/myWorkList': ['empName', 'empPhone', 'repairWorkerName'],
  '/app/repair/undonelist': ['empName', 'empPhone', 'repairWorkerName'],
  '/app/visit/anonymous/getById': ['certificateNo', 'visitName', 'mobile', 'createBy'],
  '/app/visit/getInvitationDetailByName': ['visitName', 'mobile'],
  '/app/visit/get-invitation-info': ['createBy', 'empName', 'empPhone', 'certificateNo'],
  '/app/visit/get-invitation-list': ['visitName', 'visitPhone', 'createBy'],
  '/app/visit/info': ['name', 'mobile', 'email', 'phone'],
  '/app/visit/visit': ['userName', 'name', 'email', 'mobile', 'adminPerson', 'phone'],
  '/app/visit/visit/detail': ['certificateNo', 'visitName', 'mobile', 'createBy'],
  '/app/visit/visit/list': ['certificateNo', 'visitName', 'mobile', 'createBy'],
  '/app/visit/visit/page': ['certificateNo', 'visitName', 'mobile', 'createBy'],
  '/mini-program/auth/get-many-identity': ['mobile', 'userName', 'realName', 'phone'],
  '/mini-program/common/getProjectInfo': ['phone', 'bankAccount', 'openBank', 'payee', 'merchantNo', 'merchantCsNumber', 'apiv3Key', 'certificateKey']
};
export { decryptWhiteList };
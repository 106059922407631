import request from '@/utils/request';
import qs from 'qs';
export function initData(url, params) {
  if (url === 'api/scanLog/list') {
    params = handleScanLog(params);
  } else {
    disposeTime(params);
  }
  console.log("initData", url, params);
  return request({
    url: url + '?' + qs.stringify(params, {
      indices: false
    }),
    method: 'get'
  });
}
var handleScanLog = function handleScanLog(params) {
  var scanType = params.scanType,
    entryTime = params.entryTime;
  if (scanType && scanType.length) {
    params.scanType = scanType.join(',');
  }
  if (entryTime && entryTime.length) {
    params.entryTime = undefined;
  }
  return params;
};
export function download(url, params, data) {
  params.headerTitle = data;
  disposeTime(params);
  return request({
    url: url + '?' + qs.stringify(params, {
      indices: false
    }),
    method: 'get',
    responseType: 'blob'
  });
}

// 上传附件
export function uploadData(url, data) {
  return request({
    url: url,
    ContentType: 'multipart/form-data',
    method: 'post',
    data: data
  });
}

// 获取培训视频连接
export function getTrainUrl() {
  return request({
    url: '/mini-api/common/get-train-url',
    method: 'get'
  });
}

// 获取培训文件列表
export function getTrainList() {
  return request({
    url: '/mini-api/common/get-train-list',
    method: 'get'
  });
}

/**
 * 处理时间区间参数
 *
 * @param params 列表查询参数
 */
function disposeTime(params) {
  for (var key in params) {
    // 处理时间区间参数查询
    var reg = /Time$/;
    if (!reg.test(key)) {
      continue;
    }
    var array = params[key];
    if (array instanceof Array && array.length === 2) {
      var start = key + 'Start';
      var end = key + 'End';
      params[start] = array[0];
      params[end] = array[1];
      delete params[key];
    }
  }
}
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/.jenkins/workspace/47.102.216.211-loffice-frontend-dev/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import RightPanel from "@/components/RightPanel";
import QrCode from "@/components/QrCode";
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import Theme from "@/components/ThemePicker";
import Cookies from "js-cookie";
import Footer from "@/components/Footer/Footer";
export default {
  name: "Layout",
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    QrCode: QrCode,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView,
    Theme: Theme,
    Footer: Footer
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    showModel: function showModel(state) {
      return state.settings.showModel;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  }),
  mounted: function mounted() {
    if (Cookies.get("theme")) {
      this.$refs.theme.theme = Cookies.get("theme");
      this.$store.dispatch("settings/changeSetting", {
        key: "theme",
        value: Cookies.get("theme")
      });
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  }
};
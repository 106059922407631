import "core-js/modules/es6.reflect.delete-property";
import _slicedToArray from "/root/.jenkins/workspace/47.102.216.211-loffice-frontend-dev/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.object.entries";
import { login, getInfo, logout, phoneLogin, wxCodeLogin as _wxCodeLogin, idLogin as _idLogin } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
var user = {
  state: {
    token: getToken(),
    user: {},
    roles: [],
    permissions: [],
    // 第一次加载菜单时用到
    loadMenus: false
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_LOAD_MENUS: function SET_LOAD_MENUS(state, loadMenus) {
      state.loadMenus = loadMenus;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        login(userInfo.username, userInfo.password, userInfo.code, userInfo.uuid).then(function (res) {
          console.log(res.user, "user");
          setToken(res.token, rememberMe);
          commit("SET_TOKEN", res.token);
          setUserInfo(res.user, commit);
          // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
          commit("SET_LOAD_MENUS", true);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 手机号验证码登录
    PhoneLogin: function PhoneLogin(_ref2, userInfo) {
      var commit = _ref2.commit;
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        phoneLogin(userInfo.mobile, userInfo.verificationCode).then(function (res) {
          if (res.token) {
            setToken(res.token, rememberMe);
            commit("SET_TOKEN", res.token);
            setUserInfo(res.user, commit);
            // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
            commit("SET_LOAD_MENUS", true);
          }
          resolve(res);
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },
    // 微信扫码登录-一个手机号
    wxCodeLogin: function wxCodeLogin(_ref3, userInfo) {
      var commit = _ref3.commit;
      var rememberMe = "";
      return new Promise(function (resolve, reject) {
        _wxCodeLogin(userInfo.code).then(function (res) {
          if (!res.token) {
            resolve(res);
          } else {
            setToken(res.token, rememberMe);
            commit("SET_TOKEN", res.token);
            setUserInfo(res.user, commit);
            // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
            commit("SET_LOAD_MENUS", true);
            resolve(res);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 微信扫码登录-多个手机号
    idLogin: function idLogin(_ref4, userInfo) {
      var commit = _ref4.commit;
      console.log(userInfo);
      var p = {
        id: userInfo.id,
        state: userInfo.state,
        mobile: userInfo.mobile,
        loginType: userInfo.loginType
      };
      for (var _i = 0, _Object$entries = Object.entries(p); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];
        if (value === null || value === "" || value === undefined) {
          Reflect.deleteProperty(p, key);
        }
      }
      console.log(p);
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        _idLogin(p).then(function (res) {
          console.log(res.user, "user");
          if (!res.token) {
            resolve(res);
          } else {
            setToken(res.token, rememberMe);
            commit("SET_TOKEN", res.token);
            setUserInfo(res.user, commit);
            // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
            commit("SET_LOAD_MENUS", true);
            resolve(res);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        getInfo().then(function (res) {
          setUserInfo(res.userInfo, commit);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref6) {
      var commit = _ref6.commit;
      return new Promise(function (resolve, reject) {
        logout().then(function (res) {
          logOut(commit);
          resolve();
        }).catch(function (error) {
          logOut(commit);
          reject(error);
        });
      });
    },
    updateLoadMenus: function updateLoadMenus(_ref7) {
      var commit = _ref7.commit;
      return new Promise(function (resolve, reject) {
        commit("SET_LOAD_MENUS", false);
      });
    }
  }
};
export var logOut = function logOut(commit) {
  commit("SET_TOKEN", "");
  commit("SET_ROLES", []);
  commit("SET_PERMISSIONS", []);
  removeToken();
};
export var setUserInfo = function setUserInfo(res, commit) {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.roleList.length != 0) {
    commit("SET_ROLES", res.roleList);
  } else {
    commit("SET_ROLES", -1);
  }
  commit("SET_USER", res);
  commit("SET_PERMISSIONS", res.permissionList);
};
export default user;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _vm._v(" "), _c("breadcrumb", {
    staticClass: "breadcrumb-container",
    attrs: {
      id: "breadcrumb-container"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "right-menu"
  }, [_vm.device !== "mobile" ? [_c("search", {
    staticClass: "right-menu-item",
    attrs: {
      id: "header-search"
    }
  }), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      content: "全屏缩放",
      effect: "dark",
      placement: "bottom"
    }
  }, [_c("screenfull", {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      id: "screenfull"
    }
  })], 1)] : _vm._e(), _vm._v(" "), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_vm.user.avatarPath ? _c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.user.avatarPath ? _vm.user.avatarPath + "?t=" + new Date().getTime() : ""
    }
  }) : _c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.Avatar
    }
  }), _vm._v(" "), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: function click($event) {
        _vm.showModel = true;
      }
    }
  }, [_c("el-dropdown-item", [_vm._v(" 小程序和公众号 ")])], 1), _vm._v(" "), _c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: function click($event) {
        _vm.show = true;
      }
    }
  }, [_c("el-dropdown-item", [_vm._v(" 布局设置 ")])], 1), _vm._v(" "), _c("router-link", {
    attrs: {
      to: "/user/center"
    }
  }, [_c("el-dropdown-item", [_vm._v(" 个人中心 ")])], 1), _vm._v(" "), _c("router-link", {
    attrs: {
      to: "/user/useHelp"
    }
  }, [_c("el-dropdown-item", [_vm._v(" 使用帮助 ")])], 1), _vm._v(" "), _c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: _vm.open
    }
  }, [_c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_vm._v(" 退出登录 ")])], 1)], 1)], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import request from '@/utils/request';
export function login(username, password, uuid) {
  return request({
    url: 'auth/login',
    method: 'post',
    data: {
      username: username,
      password: password,
      // code,
      uuid: uuid
    }
  });
}
export function getInfo() {
  return request({
    url: 'auth/info',
    method: 'get'
  });
}

// export function getCodeImg() {
//   return request({
//     url: 'auth/code',
//     method: 'get'
//   })
// }

export function logout() {
  return request({
    url: 'auth/logout',
    method: 'delete'
  });
}
export function phoneLogin(mobile, code) {
  return request({
    url: 'auth/phoneLogin',
    method: 'post',
    data: {
      mobile: mobile,
      code: code
    }
  });
}
export function sendMsg(mobile) {
  var params = {
    mobile: mobile
  };
  return request({
    url: 'auth/sendMsg',
    method: 'get',
    params: params
  });
}
export function wxCodeLogin(code) {
  var params = {
    code: code
  };
  return request({
    url: 'auth/wxCode',
    method: 'get',
    params: params
  });
}
export function idLogin(data) {
  return request({
    url: 'auth/idLogin',
    method: 'post',
    data: data
  });
}
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
// 千分符过滤器
export function numberToCurrencyNo(value) {
  if (!value) return 0; // 判断value 是否是以0开头的负数
  if (value < 0 && value > -1) {
    return value;
  } else {
    // 将数值截取为小数部分和整数部分
    var valueArray = value.toString().split('.');
    // 获取整数部分
    var intPart = valueArray[0]; // 整数部分处理，增加,
    var intPartFormat = intPart.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 预定义小数部分
    var floatPart = '';
    if (valueArray.length === 2) {
      // 有小数部分
      floatPart = valueArray[1].toString(); // 取得小数部分
      return intPartFormat + '.' + floatPart;
    }
    return intPartFormat + floatPart;
  }
}